import partnerAPI from '@/service/partnerAPI';
import { computed, onMounted, ref } from 'vue';

type CategoryOptions = Array<{
  createdAt: string;
  updatedAt: string;
  categoryId: number;
  categoryName: string;
  depth: number;
  parentCategoryId: any;
  order: number;
  description?: string;
  children: {
    createdAt: string;
    updatedAt: string;
    categoryId: number;
    categoryName: string;
    depth: number;
    parentCategoryId: any;
    order: number;
    description?: string;
  }[];
}>;

export default function useCategoryOptions() {
  // depth1 카테고리 배열
  const categoryOptions = ref<CategoryOptions>([]);

  // depth1의 children + children 없는 depth1 카테고리 배열
  const categoryDepth2Options = computed(() => {
    return categoryOptions.value
      .reduce((all: any[], category: any) => {
        return [...all, ...category.children];
      }, [])
      .map((v) => ({ label: v.categoryName, value: v.categoryId }));
  });

  const categoryDepth2Obj = computed(() => {
    return categoryDepth2Options.value.reduce((obj, categoryOption) => {
      return {
        ...obj,
        [categoryOption.value]: categoryOption.label,
      };
    }, {});
  });

  const categoryDepth1Options = computed(() => {
    let options = categoryOptions.value.map((category) => ({
      label: category.categoryName,
      value: category.categoryId,
    }));

    options = ([{ label: '전체', value: '' }] as any).concat(options);

    return options;
  });

  const categoryDepth1Obj = computed(() =>
    categoryDepth1Options.value.reduce((obj, categoryOption) => {
      return {
        ...obj,
        [categoryOption.value]: categoryOption.label,
      };
    })
  );

  onMounted(() => {
    partnerAPI.adminProduct
      .adminCategoryList({
        depth: 1,
      })
      .then(({ data }) => {
        categoryOptions.value = (data as any).data;
      });
  });

  return {
    categoryOptions,
    categoryDepth2Options,
    categoryDepth2Obj,
    categoryDepth1Options,
    categoryDepth1Obj,
  };
}
